// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #f3f5fa;
}

.pointer {
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.noWrap {
  white-space: nowrap;
}

.truncate-2 {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.preLineBreak {
  white-space: pre-line;
}

.ck.ck-content {
  min-height: 250px;
  max-height: 350px;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,sBAAA;EACA,kCAAA;EACA,yBAAA;AAAF;;AAGA;EACE,eAAA;AAAF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AAAF;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE,oBAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;EACA,4BAAA;AAAF;;AAGA;EACE,qBAAA;AAAF;;AAGA;EACE,iBAAA;EACA,iBAAA;AAAF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');\r\n\r\nbody {\r\n  margin: 0;\r\n  padding: 0;\r\n  box-sizing: border-box;\r\n  font-family: 'Poppins', sans-serif;\r\n  background-color: #f3f5fa;\r\n}\r\n\r\n.pointer {\r\n  cursor: pointer;\r\n}\r\n\r\n.form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: flex-start;\r\n  gap: 20px;\r\n}\r\n\r\n.noWrap {\r\n  white-space: nowrap;\r\n}\r\n\r\n.truncate-2 {\r\n  display: -webkit-box;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  -webkit-line-clamp: 2;\r\n  -webkit-box-orient: vertical;\r\n}\r\n\r\n.preLineBreak {\r\n  white-space: pre-line;\r\n}\r\n\r\n.ck.ck-content {\r\n  min-height: 250px;\r\n  max-height: 350px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
