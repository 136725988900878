import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Topbar from './topbar';
import Sidebar from './sidebar';

// COMPONENTS

function LayoutWrapper() {
  return (
    <Box>
      {/* LAYOUT CODE HERE */}
      <Topbar />

      <Box className="w-100 d-flex">
        <Box sx={{ width: '250px', backgroundColor: 'red', height: '100vh' }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: 'calc(100% - 250px)' }}>
          <Container>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default LayoutWrapper;
