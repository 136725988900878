import { Paper, Toolbar } from '@mui/material';
import React from 'react';

function Topbar() {
  return (
    <Toolbar sx={{ position: 'fixed' }}>
      <Paper className="p-4 w-100">
        Topbar
      </Paper>
    </Toolbar>
  );
}

export default Topbar;
