import React from 'react';
import { ThemeProvider } from '@mui/material';

// COMPONENTS & UTILS
import theme from 'styles/mui/theme';
import AppRoutes from './routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRoutes />
    </ThemeProvider>
  );
}

export default App;
