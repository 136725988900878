import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import GlobalLoader from 'containers/common/loaders/GlobalLoader';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';

const LogIn = lazy(() => import('containers/pages/auth/login'));
// const ForgotPassword = lazy(() => import('containers/pages/auth/forgotPassword'));
// const ChangePassword = lazy(() => import('containers/pages/auth/changePassword/index'));

const Dashboard = lazy(() => import('containers/pages/dashboard'));

// LAZY LOADING

function AppRoutes() {
  return (
    <BrowserRouter>
      <Suspense fallback={<GlobalLoader />}>
        <Routes>
          <Route path="/auth" element={<PublicRoutes />}>
            <Route path="sign-in" element={<LogIn />} />
            {/* <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ChangePassword />} /> */}
          </Route>

          <Route path="/" element={<PrivateRoutes />}>
            <Route index element={<Dashboard />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRoutes;
