import React from 'react';
import { Box } from '@mui/material';

function GlobalLoader() {
  return (
    <Box className="d-flex align-items-center justify-content-center vh-100">
      Loading..
    </Box>
  );
}

export default GlobalLoader;
